import { Link } from "gatsby"
import React from "react"
import configuration from "../../configuration"
import { Category } from "../entities/Category"
import { Obfuscated } from "./Obfuscated"

export const Footer: React.FC<{
  categories: Category[]
  obfuscation?: boolean
}> = props => {
  return (
    <footer className="w-full mt-10 bg-black">
      <div className="flex flex-col items-center justify-between w-full max-w-6xl px-4 py-8 pb-40 mx-auto space-y-8 text-xs text-white bg-black sm:space-y-0 sm:flex-row">
        <div className="">
          {configuration.manifest.short_name}
          <span className="text-gray-400"> © 2022</span>
        </div>
        <ul className="flex items-center space-x-3 text-gray-400">
          <li>
            <Obfuscated
              should={props.obfuscation}
              to="/politique-de-confidentialite/"
            >
              Politique de confidentialité
            </Obfuscated>
          </li>
          <li>•</li>
          <li>
            <Obfuscated should={props.obfuscation} to="/rss.xml">
              RSS
            </Obfuscated>
          </li>
        </ul>
        <div className="text-gray-400">
          Les articles sont écrits avec amour 🥰
        </div>
      </div>
    </footer>
  )
}
