import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import { manifest, meta } from "../../configuration"
import { Article } from "../entities/Article"

type Breadcrumb = { label: string; url: string }

const getBreadcrumb = (breadcrumbs: Breadcrumb[], endpoint: string) => {
  const items = [{ label: manifest.short_name, url: "" }, ...breadcrumbs]

  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: items.map(({ label, url }, index) => ({
      "@type": "ListItem",
      position: index + 1,
      name: label,
      item: `${endpoint}${url}`,
    })),
  }
}

const getArticle = (article: Article) => ({
  "@context": "https://schema.org",
  "@type": "Article",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id": "https://google.com/article",
  },
  headline: article.seo__title,
  image: [`${meta.protocol}://${meta.url}${article.image}`],
  author: {
    "@type": "Person",
    name: article.author.name,
  },
  publisher: {
    "@type": "Organization",
    name: manifest.short_name,
    logo: {
      "@type": "ImageObject",
      url: `${meta.protocol}://${meta.url}/favicon.svg`,
    },
  },
  datePublished: article.raw__created_at,
  dateCreated: article.raw__created_at,
  dateModified: article.raw__updated_at,
})

export const Seo: React.FC<{
  breadcrumbs?: Array<Breadcrumb>
  image?: string
  article?: Article
  description: string
  title: string
  url: string
}> = props => {
  const data = useStaticQuery(graphql`
    query SeoQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const { siteUrl } = data.site.siteMetadata

  const image = `${meta.protocol}://${meta.url}${props.image}`

  return (
    <Helmet htmlAttributes={{ lang: "fr" }} title={props.title}>
      <meta name="theme-color" content="#3B82F6" />
      <meta name="viewport" content="width=device-width" />

      <meta name="description" content={props.description} />
      <meta property="og:description" content={props.description} />

      <meta name="robots" content="max-image-preview:large" />

      <meta name="image" content={image} />
      <meta property="og:image" content={image} />
      <meta name="twitter:image" content={image} />

      <meta property="og:url" content={props.url} />
      <meta property="og:title" content={props.title} />

      <meta
        property="og:type"
        content={props.article ? "article" : "website"}
      />

      {props.article && (
        <meta
          property="article:published_time"
          content={props.article.raw__created_at.toString()}
        />
      )}

      {props.article && (
        <meta
          property="article:modified_time"
          content={props.article.raw__updated_at.toString()}
        />
      )}

      {props.article && (
        <meta property="article:author" content={props.article.author?.name} />
      )}

      {props.article && (
        <meta
          property="article:section"
          content={props.article.category?.title}
        />
      )}

      <meta name="twitter:card" content="summary_large_image" />

      {props.breadcrumbs && (
        <script type="application/ld+json">
          {JSON.stringify(getBreadcrumb(props.breadcrumbs, siteUrl))}
        </script>
      )}

      {props.article && (
        <script type="application/ld+json">
          {JSON.stringify(getArticle(props.article))}
        </script>
      )}
    </Helmet>
  )
}
